import Rails from "@rails/ujs"

document.addEventListener("turbolinks:load", function() {

  var hypedListingSetup = function() {

    // Add events to sort columns

    var sortLinks = document.querySelectorAll('[data-hyped-listing-sort-link]');

    sortLinks.forEach(element => {
      element.addEventListener('click', function (event) {
        // Don't follow the link
      	event.preventDefault();

      	// Log the clicked element in the console
      	// console.log(event.currentTarget);

        var resource = event.currentTarget.dataset.hypedListingSortLinkResource;
        var form = document.querySelector('[data-hyped-listing-form-resource="'+ resource +'"]');

        console.log(form);

        var orderByHiddenField = form.querySelector('.hyped-listing-param-order-by');
        var orderHiddenField = form.querySelector('.hyped-listing-param-order');

        // console.log(orderByHiddenField, orderHiddenField);

        orderByHiddenField.value = event.currentTarget.dataset.hypedListingSortLink
        if (orderHiddenField.value == 'asc') {
          orderHiddenField.value = 'desc'
        } else {
          orderHiddenField.value = 'asc'
        }

        if (form.dataset.remote == 'true') {
          Rails.fire(form, 'submit');
        } else {
          form.submit();
        }


      }, false);
    })

    // Add events to choose how many items will be shown

    var perPageLinks = document.querySelectorAll('[data-hyped-listing-per-page-link]');

    perPageLinks.forEach(element => {
      element.addEventListener('click', function (event) {
      	// Don't follow the link
      	event.preventDefault();

      	// Log the clicked element in the console
      	// console.log(event.currentTarget);

        var resource = event.currentTarget.dataset.hypedListingPerPageLinkResource;
        var perPage = event.currentTarget.dataset.hypedListingPerPageLink;
        var form = document.querySelector('[data-hyped-listing-form-resource="'+ resource +'"]');

        console.log(form);

        var perPageHiddenField = form.querySelector('.hyped-listing-param-per-page');

        perPageHiddenField.value = perPage

        if (form.dataset.remote == 'true') {
          Rails.fire(form, 'submit');
        } else {
          form.submit();
        }

      }, false);
    })

    // Remove duplicates hidden fields

    var forms = document.querySelectorAll('[data-hyped-listing-form-resource]');

    forms.forEach(form => {
      var otherParams = form.querySelectorAll('.hyped-listing-other-param');
      otherParams.forEach(otherParam => {

        // var inputs = document.getElementsByName(otherParam.getAttribute('name'));
        var inputs = form.querySelectorAll('[name="'+ otherParam.getAttribute('name') +'"]');

        if (inputs.length > 1) {
          otherParam.parentNode.removeChild(otherParam);
        }

      })
    })
  }

  hypedListingSetup()

  window.hypedListingSetup = hypedListingSetup

})
