
scrollAnimate = (section) => {
  $('html').animate({
    scrollTop: $(section).offset().top - 75
  }, 1000);
}

document.addEventListener("turbolinks:load", function() {
  document.getElementsByClassName("nav-logo-button")[0].addEventListener("click", function (){
    scrollAnimate('#landing-section');
  });

  document.getElementsByClassName("nav-test-button")[0].addEventListener("click", function (){
    scrollAnimate('#test-section');
  });

  document.getElementsByClassName("landing-test-button")[0].addEventListener("click", function (){
    scrollAnimate('#test-section');
  });

  document.getElementsByClassName("nav-features-button")[0].addEventListener("click", function (){
    scrollAnimate('#features-section');
  });

  document.getElementsByClassName("nav-pricing-button")[0].addEventListener("click", function (){
    scrollAnimate('#pricing-section');
  });
});
